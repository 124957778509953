import { SharedService } from '../Shared/Shared.service';
import { APIResponse, User } from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';

// import { VestingService } from '../Shared/Vesting/Vesting.service';
import VestingFacet from '../Shared/SecurityToken/Facets/VestingFacet/index';

const sharedService = new SharedService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();

export class IssuerSuperAdminService {
  private APIURL = environment.APIURL;

  public getFilePath(fileName: string) {
    return `issuerSuperAdmins/${fileName}`;
  }

  public uploadFileAsync(file: Blob) {
    const req = new FormData();
    req.append('doc', file);
    return sharedService.uploadDocumentAsync(`${this.APIURL}/issuerSuperAdmin/uploadDocument`, req);
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(
      `${this.APIURL}/issuerSuperAdmin/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }
  async getQuestions(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/getQuestions`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getQuestionsToView(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/getQuestionsToView`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setAnswer(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/setAnswer`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async submitBasicInfoVerif(form: any) {
    console.log(form);
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/submitBasicInfoVerif`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getBasicInfoVerif() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getBasicInfoVerif`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async addTeamRole(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/addTeamRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async editTeamRole(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/editTeamRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamRoles(departmentId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamRoles`,
      { params: { departmentId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamRole(teamRoleId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamRole`,
      { params: { teamRoleId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamDepartments(team?: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamDepartments`,
      { params: { team }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamDepartment(teamDepartmentId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamDepartment`,
      {
        params: { teamDepartmentId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async sendInviteToTeamMember(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendInviteToTeamMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamMembers(
    limit: number,
    searchPage: number,
    team?: 'employee' | 'contractor_consultant_Advisor',
    departmentId?: string,
    teamRoleId?: string
  ) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMembers`,
      {
        params: { limit, searchPage, team, departmentId, teamRoleId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getTeamMember(teamMemberId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMember`,
      { params: { teamMemberId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async subscriptionAgreementCreation(companyName: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/subscriptionAgreementCreation`,
      companyName,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamMembersWithoutVesting(
    tokenAddress: string | null,
    team?: 'employee' | 'contractor_consultant_Advisor',
    departmentId?: string,
    teamRoleId?: string
  ) {
    const addresses = tokenAddress
      ? await vestingFacet.getAllVestingScheduleOwnerAddresses(tokenAddress)
      : [];

    const response = await axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMembersWithoutVesting`,
      {
        params: {
          addresses: addresses.toString(),
          team,
          departmentId,
          teamRoleId,
        },
        headers: await sharedService.getAuthHeader(),
      }
    );

    const teamMembers: User[] = response.data;

    return { teamMembers, totalVestingScheduleOwners: addresses.length };
  }

  async getTeamMemberTokenConfigurationProcess() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMemberTokenConfigurationProcess`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestPriceUpdate() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/requestPriceUpdate`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendInviteToIssuerRole(req: { role: string; email: string }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendInviteToIssuerRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendRequestToCreateLegend() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendRequestToCreateLegend`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendRequestToRemoveLegend(req: { message: string }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendRequestToRemoveLegend`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveBankInfo(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveBankInfo`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getBankInfo() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getBankInfo`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getRegistrationRequest() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getRegistrationRequest`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getCompanyMember(memberId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getCompanyMember`,
      { params: { memberId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async editCompanyMember(req: {
    team: string;
    corporateRoles: string[];
    department: string;
    teamRoleId: string;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/editCompanyMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenTypeDetail(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTokenTypeDetail`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/setIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getDueDiligenceList(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getDueDiligenceList`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setDueDiligenceAnswer(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/setDueDiligenceAnswer`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async currentOfferingStatus(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/currentOfferingStatus`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handleCreateOfferingAccount(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handleCreateOfferingAccount`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handlePrimeTrustEscrowAccount(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handlePrimeTrustEscrowAccount`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async handlePrimeTrustSign(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handlePrimeTrustSign`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async fileEscrowServiceApplication(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/fileEscrowServiceApplication`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPrimeTrustEntity(params) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getPrimeTrustEntity`,
      { params, headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuerDueDiligence(params) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIssuerDueDiligence`,
      { params, headers: await sharedService.getAuthHeader() }
    );
  }
  async getAnsweredDueDligenceList(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getAnsweredDueDligenceList`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async savePaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSavedPaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getSavedPaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
}
