import React, {
  useState,
  useEffect,
} from "react";
import CardHOC from "../CardHOC";
import {Button, Form, Input, Modal, Select, Spin, InputNumber, Typography, Descriptions} from "antd";
import {AuthService} from "../Shared/Auth.service";
import {MetamaskService} from "../Shared/Metamask.service";
import {SCFieldsService} from "./SCFields.service";
import {SCField} from "../Shared/interfaces";
import {SharedService} from "../Shared/Shared.service";
import {environment} from "../../environments/environment";
import TransactionModal from "../Shared/TransactionModal/TransactionModal";
const { Option } = Select;
const { Title } = Typography;
const scFieldsService = new SCFieldsService();
const sharedService = new SharedService();


const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function SCFields() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const {selectedWallet} = useSelectedWalletContext();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [modalForm] = Form.useForm();
  const [fieldsForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const [scFields, setScFields] = useState<SCField[]>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
    >([]);

  // const contractAddress = '0xf3eDf99599C9651147581f677a3FfDE9DD78ab01'; //TODO: GET FROM DB
  const [contractAddress, setContractAddress] = useState('');
  const fieldTypes = [
    {
      name: 'Short Text',
      value: 'text'
    },
    {
      name: 'Long Text',
      value: 'text area'
    },
    {
      name: 'Number',
      value: 'number'
    }
  ];

  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet || !contractAddress) return;

      await getForm();

      setLoading(false);
    })();
  },[userInfo, selectedWallet, contractAddress]);


  const getForm = async() => {
    const _scFields = (await scFieldsService.getFields()).data as SCField[];

    const encryptedFieldAnswersHex = await scFieldsService.getFieldAnswers(
      contractAddress,
      _scFields.map(field => field.creationTS)
    );

    console.log({encryptedFieldAnswersHex});

    const encryptedFieldAnswers = {};

    _scFields.forEach((field, i) => {
      encryptedFieldAnswers[field.creationTS] = encryptedFieldAnswersHex[i];
      // encryptedFieldAnswers[field.creationTS] = sharedService.bytes32ToString(encryptedFieldAnswersHex[i]);
    });

    console.log({encryptedFieldAnswers});

    const decrypted = (await scFieldsService.decryptFieldAnswers(encryptedFieldAnswers)).data;

    console.log({decrypted});

    _scFields.forEach((field, i) => {
      field['answer'] = decrypted[field.creationTS];
    });

    setScFields(_scFields);
  }

  const deployMasterContract = async() => {
    setIsModalVisible(true);
    setTransactions([{ details: 'Deploying Master Contract', submitting: true }]);

    try {
    const receipt = await scFieldsService.deploy(selectedWallet as string);

    if(receipt.status) {
      setContractAddress(receipt.contractAddress as string);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current[0].receipt = receipt;
      return current;
    });

    }catch (e) {
      console.error(e);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current.forEach(c => c.submitting = false);
      return current;
    });
  }



  const saveField = async(formValue) => {
    console.log(formValue);

    setSubmitting(true);

    const response = await scFieldsService.saveField(formValue);

    if(response.success) {
      setScFields(prev => {
        const current = sharedService.clone(prev);

        current.push({
          _id: response.data._id,
          creationTS: response.data.creationTS,
          apiKey: environment.apiKey,
          companyId: userInfo?.company?.id,
          label: formValue.label,
          type: formValue.type,
        })

        return current
      });

      setIsAddModalVisible(false);
      modalForm.resetFields();

    }

    setSubmitting(false);
  }

  const submitForm = async(formValue) => {
    console.log({formValue});

    setIsModalVisible(true);
    setTransactions([{ details: 'Submitting Form', submitting: true }]);

    try {

      const encrypted = (await scFieldsService.encryptFieldAnswers(formValue)).data;
      console.log({encrypted});

      const receipt = await scFieldsService.saveFieldAnswers(
        contractAddress,
        selectedWallet as string,
        Object.keys(encrypted),
        Object.values(encrypted)
      // Object.values(encrypted).map(value => sharedService.stringToBytes32(value as string))
      );

      if (receipt.status) getForm();

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });



    }catch (e) {
      console.error(e);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current.forEach(c => c.submitting = false);
      return current;
    });

  }

  return (
    <CardHOC
      heading={'Smart Contract Fields'}
      component={<>

        {!contractAddress &&
          <Button
            size="large"
            type="primary"
            htmlType={'submit'}
            onClick={deployMasterContract}
          >
            DEPLOY MASTER CONTRACT
          </Button>
        }

        {loading && contractAddress &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading && contractAddress &&
          <>
            <Form
              form={fieldsForm}
              autoComplete={'off'}
              onFinish={submitForm}
            >
              {scFields?.length
                ?
                  <Title level={3}> Fill Form</Title>
                :
                  <Title level={3}>No Fields Saved yet</Title>
              }

              {scFields?.map(scField => (

                scField.type === 'text'
                ?
                  <Form.Item
                    name={scField.creationTS}
                    label={scField.label}
                    key={scField._id}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder={scField.label}
                    />
                  </Form.Item>
                :

                scField.type === 'text area'
                ?
                  <Form.Item
                    name={scField.creationTS}
                    label={scField.label}
                    key={scField._id}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={scField.label}
                    />
                  </Form.Item>
                :

                scField.type === 'number'
                ?
                  <Form.Item
                    name={scField.creationTS}
                    label={scField.label}
                    key={scField._id}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={scField.label}
                    />
                  </Form.Item>
                :
                null

              ))}

              <div style={{ textAlign: 'left' }}>
                <Button
                  style={{marginRight: '10px'}}
                  onClick={() => setIsAddModalVisible(true)}
                >
                  ADD FIELD
                </Button>
              </div>

              <div style={{ textAlign: 'center' }}>
                {!!scFields?.length &&
                  <Button
                    size="large"
                    type="primary"
                    htmlType={'submit'}
                  >
                    SUBMIT
                  </Button>
                }

              </div>
            </Form>


            <br/><br/>
            <Title level={3}>Filled Form</Title>

            <Descriptions bordered column={2}>
              {scFields?.map(scField => (
                <React.Fragment key={scField._id}>
                  {scField['answer'] &&
                    <Descriptions.Item span={2} label={scField.label}>
                      {scField['answer']}
                    </Descriptions.Item>
                  }
                </React.Fragment>
              ))}
            </Descriptions>

            <br/><br/>

          </>
        }


        <Modal
          title={'Save field'}
          visible={isAddModalVisible}
          cancelText="CANCEL"
          onCancel={() => {
            setIsAddModalVisible(false);
            modalForm.resetFields();
          }}
          okText={'SUBMIT'}
          onOk={() => modalForm.submit()}
          okButtonProps={{
            loading: submitting,
          }}
        >
          <Form
            form={modalForm}
            autoComplete={'off'}
            onFinish={saveField}
          >

            <Form.Item
              name={'label'}
              label={'Label'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input
                placeholder={'Label'}
              />
            </Form.Item>

            <Form.Item
              name={'type'}
              label={'Type'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select Field Type"
              >
                {fieldTypes?.map(fielType =>
                  <Option key={fielType.value} value={fielType.value}>{fielType.name}</Option>
                )}
              </Select>
            </Form.Item>

          </Form>
        </Modal>

        <TransactionModal
          title={'Master Contract'}
          transactions={transactions}
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
        />

      </>}
    />
  );
}