// import React from 'react';
import {MetamaskService} from '../Metamask.service';
import {APIResponse, SymbolDetailsAndSTData} from '../interfaces';
import axios from 'axios';
import {environment} from '../../../environments/environment';
import {TrustedForwarderService} from "../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();


const headers = {
  'Content-Type': 'application/json',
  'apiKey':environment.apiKey
}


export class SecurityTokenRegistryService {

  private APIURL = environment.APIURL;

  public contract = {
    address: environment.securityTokenRegistryAddress,
    ABI: require('./ABI.json')
  }

  private get web3() {
    return MetamaskService.web3;
  }

  async tokenSymbolAvailable(tokenSymbol: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.methods.tokenSymbolAvailable(tokenSymbol).call();
  }

  registerNewTokenSymbol(
    owner: string,
    tokenSymbol: string,
    _expiryLimit: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    const method = contractInstance.methods.registerNewTokenSymbol(owner, tokenSymbol);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: this.contract.address,
      signerAddress: owner
    });
  }

  async getAllOwnerSymbolsDetailsAndSTData(owner: string): Promise<SymbolDetailsAndSTData[]> {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return (await contractInstance.methods.getAllOwnerSymbolsDetailsAndSTData(owner).call());
  }

  async getSymbolDetailsAndSTData(symbol: string): Promise<SymbolDetailsAndSTData> {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return (await contractInstance.methods.getSymbolDetailsAndSTData(symbol).call());
  }

  getSymbolRegistrationEvent(owner: string, registrationDate: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.getPastEvents('RegisterTokenSymbol', {
      filter: {
        _owner: owner,
        _registrationDate: registrationDate
      },
      fromBlock: 0,
      toBlock: 'latest'
    });
  }

  async generateNewSecurityToken(
    owner: string, 
    name: string, 
    tokenSymbol: string, 
    decimals: number, 
    tokenDetails: string, 
    _regulation: string,
    _regDTransferableOutsideUSA: boolean,
    _creationTS: number,
    _typeOfSecurity: string,
    _commonStock: string,
    issuersWallets: string[],
    _monthUnit: number,
    _trustedForwarder: string,
    extra: {delegate: boolean} = {delegate: false}
    ) {
    // const issuersWallets: string[] = (await tokenConfigurationService.getIssuersWallets()).data;

    console.log(issuersWallets);

    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    const method = contractInstance.methods.generateNewSecurityToken(
      [name, tokenSymbol, tokenDetails],
      decimals,
      issuersWallets,
      _trustedForwarder,
      _monthUnit,
      [_regulation, _regDTransferableOutsideUSA, _creationTS, _typeOfSecurity, _commonStock]
    );

    if(!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: this.contract.address,
      signerAddress: owner
    });


  }

  async getSymbolsDetailsAndSTData(symbols: string[]): Promise<SymbolDetailsAndSTData[]> {    
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);
    return (await contractInstance.methods.getSymbolsDetailsAndSTData(symbols).call());
  }

  async getDeployedTokensData(): Promise<SymbolDetailsAndSTData['securityTokenData'][]> {    
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return (await contractInstance.methods.getDeployedTokensData().call());
  }


  async getCompaniesAndTokenInfo() {
    const tokens = await this.getDeployedTokensData();

    const symbols = tokens.map(token => token.symbol);

    const response = await axios.get<any, APIResponse>(`${this.APIURL}/shared/getCompaniesAndTokenInfo`, {params: {symbols}, headers});

    if(response.success) {
      response.data = (response.data as any[])
        // .filter(data => )
        .map(data => {

        const tokenInfoFound = tokens.find(token => token.symbol === data.tokenSymbol);

        return {
          tokenInfo: {
            name: tokenInfoFound?.name,
            symbol: tokenInfoFound?.symbol,
            contractAddress: tokenInfoFound?.contractAddress,
            decimals: tokenInfoFound?.decimals,
            tokenDetails: tokenInfoFound?.tokenDetails,
            deployedAt: tokenInfoFound?.deployedAt

          },
          ...data
        };
      });
    }

    return response;
  }

  async filterTokenSymbolsFromContract() {
    const tokens = await this.getDeployedTokensData();

    const symbols = tokens.map(token => token.symbol);

    const response = await axios.get<any, APIResponse>(`${this.APIURL}/shared/filterTokenSymbolsFromContract`, {params: {symbols}, headers});

    return tokens.filter(token => (response.data as string[]).find(s => s === token.symbol));
  }

  capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  camelCaseToText(value: string) {
    return this.capitalizeFirstLetter(value).replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  private static formatFunctionCallName(value: string) {
    if(value === 'registerNewTokenSymbol') return 'ReserveTokenSymbol';
    if(value === 'generateNewSecurityToken') return 'DeploySecurityToken';
    return value;
  }

  private getSecurityTokenRegistrySignatures() {
    return this.contract.ABI
      .filter(ABIEl => ABIEl.type === "function")
      .map(ABIEl => ({
        function: ABIEl.name as string,
        signature: this.web3.eth.abi.encodeFunctionSignature(ABIEl),
        label: this.camelCaseToText(SecurityTokenRegistryService.formatFunctionCallName(ABIEl.name))
      }));
  }

  findSecurityTokenRegistrySignatures(prop: {data: string}) {
    return this.getSecurityTokenRegistrySignatures().find(result =>
      prop.data
        .toLowerCase()
        .startsWith(result.signature.toLowerCase())
    );
  }


  
}
