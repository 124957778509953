import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Descriptions } from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';


const { Title } = Typography;


const issuerSuperAdminService = new IssuerSuperAdminService();


export default function BankInfo() {

  const [submitting, setSubmitting] = useState(false);
  const [bankInfo, setBankInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isEditionMode, setIsEditionMode] = useState(false);


  useEffect(() => {
    (async () => {
      setLoading(true);

      const _bankInfo = (await issuerSuperAdminService.getBankInfo()).data;
      setBankInfo(_bankInfo);

      setLoading(false);
    })();
  }, []);



  const saveBankInfo = async (formValue) => {

    console.log(formValue);
    setSubmitting(true);
    const response = await issuerSuperAdminService.saveBankInfo(formValue);

    if (response.success) {
      notification.success({ message: 'Success', description: 'Bank Info saved successfully' });
      setBankInfo(formValue);
      setIsEditionMode(false);

    } else {
      notification.error({ message: 'Error', description: response.error.message });

    }

    setSubmitting(false);
  }


  return (
    <>
      <br /><br />
      <Row justify="center">
        <Col span={20}>
          {loading &&
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size='large' />
            </div>
          }
          {!loading &&
            <Card>
              <Title level={1} style={{ textAlign: 'center' }}>Bank Information</Title>

              {(!bankInfo || isEditionMode) &&
                <Form labelAlign="left" initialValues={isEditionMode ? bankInfo : undefined} onFinish={saveBankInfo}>
                  <Form.Item
                    label="Recipient's name"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='recipientName'>
                    <Input />
                  </Form.Item>

                  {/* <Form.Item 
                    label="Account Type"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 }}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='accountType'>
                    <Input/>
                  </Form.Item> */}

                  <Form.Item
                    label="Recipient Bank Routing number"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='ACHRoutingNumber'>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Recipient Bank Name"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='bankName'>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Recipient Bank Account Number"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='bankAccountNumber'>
                    <Input />
                  </Form.Item>

                  {/* <Form.Item 
                    label="Name of Beneficiary on Bank Account"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 }}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='nameOfBeneficiary'>
                    <Input/>
                  </Form.Item> */}
                  <Form.Item
                    label="Recipient Bank Phone No"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='phoneNo'
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    label="Recipient Bank Swift code"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='swiftCode'>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Recipient Bank Address"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='bankAddress'>
                    <Input />
                  </Form.Item>
                  {/* <Form.Item
                    label="Skrill Merchant Id"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='skrillMerchantId'>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Skrill Phone Number"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name='skrillPhoneNo'>
                    <Input />
                  </Form.Item> */}

                  <div style={{ textAlign: 'right' }}>
                    {isEditionMode &&
                      <Button style={{ marginRight: '5px' }} size='large' onClick={() => setIsEditionMode(false)}>
                        BACK
                      </Button>
                    }
                    <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                      SAVE
                    </Button>
                  </div>
                </Form>
              }

              {(bankInfo && !isEditionMode) &&
                <>
                  {/* <Descriptions bordered>
                    <Descriptions.Item label="Recipient's name">{bankInfo.recipientName}</Descriptions.Item>
                    <Descriptions.Item label="Account Type">{bankInfo.accountType}</Descriptions.Item>
                    <Descriptions.Item label="ACH Routing Number">{bankInfo.ACHRoutingNumber}</Descriptions.Item>
                    <Descriptions.Item label="Bank Name">{bankInfo.bankName}</Descriptions.Item>
                    <Descriptions.Item label="Bank Account Number">{bankInfo.bankAccountNumber}</Descriptions.Item>
                    <Descriptions.Item label="Name of Beneficiary on Bank Account">{bankInfo.nameOfBeneficiary}</Descriptions.Item>
                  </Descriptions> */}
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient's name</b></Col>
                    <Col>{bankInfo.recipientName}</Col>
                  </Row>
                  {/* <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Account Type</b></Col>
                    <Col>{bankInfo.accountType}</Col>
                  </Row> */}
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Name</b></Col>
                    <Col>{bankInfo.bankName}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Address</b></Col>
                    <Col>{bankInfo.bankAddress}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Account Number</b></Col>
                    <Col>{bankInfo.bankAccountNumber}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Routing number</b></Col>
                    <Col>{bankInfo.ACHRoutingNumber}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Swift Code</b></Col>
                    <Col>{bankInfo.swiftCode}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Bank Phone No</b></Col>
                    <Col>{bankInfo.phoneNo}</Col>
                  </Row>
                  {/* <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Skrill Email Id</b></Col>
                    <Col>{bankInfo.skrillMerchantId}</Col>
                  </Row>
                  <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Recipient Skrill Phone Number</b></Col>
                    <Col>{bankInfo.skrillPhoneNo}</Col>
                  </Row> */}
                  {/* <Row>
                    <Col span={14} style={{ textAlign: "center" }}><b>Name of Beneficiary on Bank Account</b></Col>
                    <Col>{bankInfo.nameOfBeneficiary}</Col>
                  </Row> */}
                  <br />
                  <div style={{ textAlign: 'right' }}>
                    <Button htmlType='submit' size='large' type='primary' onClick={() => setIsEditionMode(true)}>
                      EDIT
                    </Button>
                  </div>
                </>
              }

            </Card>
          }
        </Col>
      </Row>
    </>
  );
}