import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, notification, Spin, Select, Table, Modal, Radio } from "antd";
import { InvestorService } from "../Investor/Investor.service";
import { AuthService } from "../Shared/Auth.service";
import WhitelistFacet from "../Shared/SecurityToken/Facets/WhitelistFacet";
import { SharedService } from "../Shared/Shared.service";
import { SecurityTokenRegistryService } from "../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { MetamaskService } from "../Shared/Metamask.service";
import { SymbolDetailsAndSTData, WalletWhitelistRequest } from "../Shared/interfaces";
import CardHOC from "../CardHOC";
const { Option } = Select;

const investorService = new InvestorService();
const sharedService = new SharedService();
const securityTokenRegistryService = new SecurityTokenRegistryService();
const whitelistFacet = new WhitelistFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function WhitelistedWalletRequests() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [selectedToken, setSelectedToken] = useState<SymbolDetailsAndSTData['securityTokenData']>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const [tokens, setTokens] = useState<SymbolDetailsAndSTData['securityTokenData'][]>();
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [form] = Form.useForm();
  // const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>();
  // const [loadingRegulationsLaunched, setLoadingRegulationsLaunched] = useState(true);
  const [walletWhitelistRequests, setWalletWhitelistRequests] = useState<WalletWhitelistRequest[]>();

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;


      const _tokens = await securityTokenRegistryService.filterTokenSymbolsFromContract();
      setTokens(_tokens);

      setLoading(false)
    })();
  }, [userInfo, selectedWallet]);


  useEffect(() => {
    if (!selectedWallet) return;

    form.setFieldsValue({ wallet: selectedWallet });

  }, [selectedWallet]);


  const selectToken = async (tokenSymbol: string) => {
    // setLoadingRegulationsLaunched(true);

    const _selectedToken = tokens?.find(token => token.symbol === tokenSymbol);

    const [reqRes] = await Promise.all([
      // mainFacet.getRegulationsLaunched(_selectedToken?.contractAddress as string),
      investorService.getWalletWhitelistRequests({
        tokenSymbol: _selectedToken?.symbol as string
      })
    ]);
    const _walletWhitelistRequests: WalletWhitelistRequest[] = reqRes.data;


    const whitelistedWallets = await Promise.all(
      _walletWhitelistRequests.map(req => whitelistFacet.getWhitelistedWallet(
        _selectedToken?.contractAddress as string,
        req.wallet
      ))
    );

    _walletWhitelistRequests.forEach(req => {
      req['isWhiteListed'] = whitelistedWallets
        .find(whitelistedWallet => whitelistedWallet.wallet.toLowerCase() === req.wallet.toLowerCase())
        ?.isWhiteListed;
    });

    setWalletWhitelistRequests(_walletWhitelistRequests);
    setSelectedToken(_selectedToken);
    // setRegulationsLaunched(_regulationsLaunched);
    // setLoadingRegulationsLaunched(false);
  }

  const openRequestModal = async () => {
    setIsRequestModalVisible(true);
    form.setFieldsValue({ wallet: selectedWallet });
  }

  const requestWalletWhitelist = async (formValue) => {
    console.log(formValue);

    if (walletWhitelistRequests?.find(req => req.wallet === selectedWallet?.toLowerCase())) {
      message.error("Whitelist already requested");
      return;
    }

    setSubmitting(true);

    const response = await investorService.requestWalletWhitelist({
      ...formValue,
      tokenSymbol: selectedToken?.symbol as string,
      wallet: selectedWallet?.toLowerCase(),
      country: userInfo?.country,
      isAccredetedInvestor: ((userInfo?.aiVerifiedCompany)?.length as number) > 0,
    });

    if (response.success) {
      setWalletWhitelistRequests(response.data);
      setIsRequestModalVisible(false);

      notification.success({
        message: "Wallet saved successfully",
        description: "wait until it will be whitelisted",
      });
    } else {
      message.error("Something went wrong.Please try again");
    }

    setSubmitting(false)
  }


  const columns = [
    {
      title: 'Wallet',
      dataIndex: 'wallet',
      render: (value) =>
        <a
          style={{ textDecoration: 'underline' }}
          href={`${sharedService.etherscanURL[networkId as string]
            }/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
    },
    // {
    //   title: 'Regulation',
    //   dataIndex: 'regulation',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.regOptions.find(
    //       (regOp) =>
    //         regOp.shortValue === regLaunched?.regulation
    //     )?.name as string
    //   }
    // },
    // {
    //   title: 'Type Of Security',
    //   dataIndex: 'typeOfSecurity',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.typeOfSecurityOptions.find(
    //       opt => opt.value === regLaunched?.typeOfSecurity
    //     )?.name
    //   }
    // },
    // {
    //   title: 'Common Stock',
    //   dataIndex: 'commonStock',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.commonStockOptions.find(
    //       opt => opt.value === regLaunched?.commonStock
    //     )?.name
    //   }
    // },
    {
      title: 'Whitelisted',
      dataIndex: 'isWhiteListed',
      render: (value: boolean) => (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {value && <span style={{ color: 'green' }}>YES</span>}
          {!value && <span style={{ color: 'orange' }}>NO</span>}
        </div>
      )
    }
  ];



  return (
    <CardHOC
      heading={'Whitelisted Wallets'}
      component={<>
        {loading &&
          <div style={{ textAlign: 'center' }}>
            <br /><br />
            <Spin size='large' />
          </div>
        }
        {!loading &&
          <>
            <div style={{ textAlign: 'center' }}>
              <br />
              <Select size="large" showSearch placeholder="Select Token" onChange={e => selectToken(e as string)}>
                {tokens?.map(token =>
                  <Option key={token.symbol} value={token.symbol}>{token.name} ({token.symbol})</Option>
                )}
              </Select>
              <br /><br />

              {selectedToken &&
                <>
                  {!walletWhitelistRequests &&
                    <div style={{ textAlign: 'center' }}>
                      <br /><br />
                      <Spin size='large' />
                    </div>
                  }

                  {walletWhitelistRequests &&
                    <>
                      <Table
                        columns={columns}
                        dataSource={walletWhitelistRequests}
                        pagination={false}
                        scroll={{ x: true }}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="submit"
                          onClick={openRequestModal}
                        >
                          REQUEST WHITELIST
                        </Button>
                      </div>
                    </>
                  }
                </>
              }
            </div>
          </>
        }


        <Modal
          title={'Request Whitelist'}
          visible={isRequestModalVisible}
          width={1000}
          cancelText="CANCEL"
          onCancel={() => {
            setIsRequestModalVisible(false);
            form.resetFields();
          }}
          okText={'SUBMIT'}
          onOk={() => form.submit()}
          okButtonProps={{
            loading: submitting,
          }}
        >
          {/*{loadingRegulationsLaunched &&*/}
          {/*  <div style={{textAlign:'center'}}>*/}
          {/*    <br/><br/>*/}
          {/*    <Spin size='large'/>*/}
          {/*  </div>*/}
          {/*}*/}

          {
            // !loadingRegulationsLaunched && regulationsLaunched &&
            <Form
              form={form}
              autoComplete={'off'}

              onFinish={requestWalletWhitelist}
            >
              <>
                <Form.Item
                  name={'wallet'}
                  label={'Select Wallet on Metamask'}
                  wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input
                    placeholder={'Select Wallet'}
                    disabled
                    style={{ color: 'blue', fontWeight: 'bold' }}
                  // @ts-ignore
                  // labelCol={{span: 24}}
                  />
                </Form.Item>

                {/*<Form.Item*/}
                {/*  name={'assetCreationTS'}*/}
                {/*  label={'Select Asset Type Launched'}*/}
                {/*  rules={[*/}
                {/*    {*/}
                {/*      required: true,*/}
                {/*      message: "This field is required",*/}
                {/*    },*/}
                {/*  ]}*/}
                {/*>*/}
                {/*  <Radio.Group>*/}
                {/*    {regulationsLaunched.map((regulationLaunched, index) => (*/}
                {/*      <Radio value={+regulationLaunched.creationTS} key={index}>*/}
                {/*        <span style={{ fontWeight: 'bold' }}>*/}
                {/*          {*/}
                {/*            sharedService.assetName(regulationLaunched)*/}
                {/*          }*/}
                {/*        </span>*/}
                {/*          {' - '}*/}
                {/*          Launched on: {moment(+regulationLaunched.dateLaunched * 1000).format('LL')}*/}
                {/*      </Radio>*/}
                {/*    ))}*/}
                {/*  </Radio.Group>*/}
                {/*</Form.Item>*/}
              </>

            </Form>
          }


        </Modal>


      </>}
    />
  );

}