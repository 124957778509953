import React, {
  useState,
  useEffect,
} from "react";
import CardHOC from "../CardHOC";
import {Button, Form, Input, Modal, Select, Spin, InputNumber, Typography, Descriptions, Checkbox} from "antd";
import {AuthService} from "../Shared/Auth.service";
import {MetamaskService} from "../Shared/Metamask.service";
import {MasterCompanyDetails, SCField} from "../Shared/interfaces";
import {SharedService} from "../Shared/Shared.service";
import {environment} from "../../environments/environment";
import TransactionModal from "../Shared/TransactionModal/TransactionModal";
import MasterCompanyService from "./MasterCompany.service";
const { Option } = Select;
const { Title } = Typography;


const masterCompanyService = new MasterCompanyService();
const sharedService = new SharedService();


const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default function MasterCompany() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [modalForm] = Form.useForm();
  const [fieldsForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const [scFields, setScFields] = useState<SCField[]>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
    >([]);

  const [masterCompanyDetails, setMasterCompanyDetails] = useState<MasterCompanyDetails>();
  const [closingTransactionModal, setClosingTransactionModal] = useState(false);


  const fieldTypes = [
    {
      name: 'Short Text',
      value: 'text'
    },
    // {
    //   name: 'Long Text',
    //   value: 'text area'
    // },
    {
      name: 'Number',
      value: 'number'
    }
  ];

  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      const _masterCompanyDetails = (await masterCompanyService.getMasterCompanyDetails()).data;
      setMasterCompanyDetails(_masterCompanyDetails);

      await getForm();

      setLoading(false);
    })();
  }, [userInfo, selectedWallet]);


  const getForm = async() => {
    const _scFields = (await masterCompanyService.getFields()).data as SCField[];

    // const fieldAnswers = await masterCompanyService.getFieldAnswers(
    //   masterCompanyDetails?.contractAddress as string,
    //   _scFields.map(field => field.creationTS)
    // );

    setScFields(_scFields);
  };



  const saveField = async(formValue) => {
    console.log(formValue);

    setSubmitting(true);

    const response = await masterCompanyService.saveField(formValue);

    if(response.success) {
      setScFields(prev => {
        const current = sharedService.clone(prev);

        current.push({
          _id: response.data._id,
          creationTS: response.data.creationTS,
          apiKey: environment.apiKey,
          companyId: userInfo?.company?.id,
          label: formValue.label,
          type: formValue.type,
          saveInContract: !!formValue.saveInContract
        })

        return current
      });

      setIsAddModalVisible(false);
      modalForm.resetFields();

    }

    setSubmitting(false);
  }



  const deployMasterContract = async(formValue) => {
    const _formValue = {};

    Object.keys(formValue).forEach(key => {
      if(scFields?.find(field => field.creationTS === +key)?.saveInContract) {
        _formValue[key] = formValue[key];
      }
    });

    console.log({_formValue});

    setIsModalVisible(true);
    setTransactions([
      { details: 'Deploying Master Company Contract', submitting: true },
      { details: 'Saving Details', submitting: false }
    ]);

    try {
      const receipt1 = await masterCompanyService.deploy(selectedWallet as string);

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].submitting = false;
        current[0].receipt = receipt1;
        return current;
      });

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[1].submitting = true;
        return current;
      });

      const receipt2 = await masterCompanyService.saveFieldAnswers(
        receipt1.contractAddress as string,
        selectedWallet as string,
        Object.keys(_formValue),
        Object.values(_formValue)
        // Object.values(encrypted).map(value => sharedService.stringToBytes32(value as string))
      );

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[1].submitting = false;
        current[1].receipt = receipt2;
        return current;
      });

      await masterCompanyService.saveMasterCompanyDetails({
        contractAddress: receipt1.contractAddress as string,
        answers: Object.entries(formValue)
      });

    } catch (e) {
      console.error(e);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current.forEach(c => c.submitting = false);
      return current;
    });
  }

  const closeTransactionModal = async() => {
    setClosingTransactionModal(true);
    await getForm();
    const _masterCompanyDetails = (await masterCompanyService.getMasterCompanyDetails()).data;
    setMasterCompanyDetails(_masterCompanyDetails);
    setClosingTransactionModal(false);
    setIsModalVisible(false);
  }



  return (
    <CardHOC
      heading={'Master Company Details'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            {masterCompanyDetails &&
              <>

                <Title
                  level={2}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Master Company Contract Address
                </Title>
                <a
                  href={`${sharedService.etherscanURL[networkId as string]}/address/${masterCompanyDetails.contractAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Title
                    style={{ color: "royalblue", textDecoration: "underline" }}
                    level={3}
                  >
                    {masterCompanyDetails.contractAddress}
                  </Title>
                </a>

                <br/><br/>

                <Descriptions bordered column={2}>
                  {scFields?.map(scField => (
                    <React.Fragment key={scField._id}>
                      {scField['answer'] &&
                      <Descriptions.Item span={2} label={scField.label}>
                        {scField['answer']}
                      </Descriptions.Item>
                      }
                    </React.Fragment>
                  ))}
                </Descriptions>

              </>
            }

            {!masterCompanyDetails &&
              <>

                <Form
                  form={fieldsForm}
                  autoComplete={'off'}
                  onFinish={deployMasterContract}
                >
                  {scFields?.length
                    ?
                    <Title level={3}> Fill Form</Title>
                    :
                    <Title level={3}>No Fields Saved yet</Title>
                  }

                  {scFields?.map(scField => (

                    scField.type === 'text'
                      ?
                      <Form.Item
                        name={scField.creationTS}
                        label={scField.label}
                        key={scField._id}
                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder={scField.label}
                        />
                      </Form.Item>
                      :

                      scField.type === 'text area'
                        ?
                        <Form.Item
                          name={scField.creationTS}
                          label={scField.label}
                          key={scField._id}
                          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder={scField.label}
                          />
                        </Form.Item>
                        :

                        scField.type === 'number'
                          ?
                          <Form.Item
                            name={scField.creationTS}
                            label={scField.label}
                            key={scField._id}
                            wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder={scField.label}
                            />
                          </Form.Item>
                          :
                          null

                  ))}

                  <div style={{ textAlign: 'left' }}>
                    <Button
                      style={{marginRight: '10px'}}
                      onClick={() => setIsAddModalVisible(true)}
                    >
                      ADD FIELD
                    </Button>
                  </div>

                  <div style={{ textAlign: 'center' }}>
                    {!!scFields?.length &&
                    <Button
                      size="large"
                      type="primary"
                      htmlType={'submit'}
                    >
                      CREATE MASTER COMPANY CONTRACT
                    </Button>
                    }

                  </div>
                </Form>

              </>
            }

          </>
        }


        <Modal
          title={'Save field'}
          visible={isAddModalVisible}
          cancelText="CANCEL"
          onCancel={() => {
            setIsAddModalVisible(false);
            modalForm.resetFields();
          }}
          okText={'SUBMIT'}
          onOk={() => modalForm.submit()}
          okButtonProps={{
            loading: submitting,
          }}
        >
          <Form
            form={modalForm}
            autoComplete={'off'}
            onFinish={saveField}
          >

            <Form.Item
              name={'label'}
              label={'Label'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input placeholder={'Label'}/>
            </Form.Item>

            <Form.Item
              name={'type'}
              label={'Type'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select Field Type"
              >
                {fieldTypes?.map(fielType =>
                  <Option key={fielType.value} value={fielType.value}>{fielType.name}</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              name={'saveInContract'}
              // label={'Save In Contract'}
              valuePropName="checked"
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              // rules={[
              //   {
              //     required: true,
              //     message: "This field is required",
              //   },
              // ]}
            >
              <Checkbox>Save In Contract</Checkbox>
            </Form.Item>

          </Form>
        </Modal>

        <TransactionModal
          title={'Master Contract'}
          transactions={transactions}
          isModalVisible={isModalVisible}
          closingModal={closingTransactionModal}
          closeModal={closeTransactionModal}
        />

      </>}
    />
  )
}