import { Button, Card, Col, Form, Input, Modal, notification, Result, Row, Spin, Table, Typography, Descriptions } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import {
  RecoveryRequest,
  RegLaunched,
  SymbolDetailsAndSTData,
  User,
  WalletWhitelistRequest,
  WhitelistedWallet
} from '../../Shared/interfaces';
import { MetamaskService } from '../../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SharedService } from '../../Shared/Shared.service';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { RecoveryRequestsService } from '../RecoveryRequests.service';
import moment from 'moment';
import TransactionModal from '../../Shared/TransactionModal';


import WhitelistFacet from '../../Shared/SecurityToken/Facets/WhitelistFacet/index';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import WrongMetamaskWalletWarning from "../../Shared/WrongMetamaskWalletWarning";
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";
import ERC20Facet from "../../Shared/SecurityToken/Facets/ERC20Facet";
import ERC1594Facet from "../../Shared/SecurityToken/Facets/ERC1594Facet";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";

const {Title} = Typography;
const recoveryRequestsService = new RecoveryRequestsService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();

const whitelistFacet = new WhitelistFacet();
const mainFacet = new MainFacet();
const erc1410Facet = new ERC1410Facet();
const erc20Facet = new ERC20Facet();
const erc1594Facet = new ERC1594Facet();

export default function RecoveryRequestsDetails() {
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [recoveryProcessReq, setRecoveryProcessReq] = useState<RecoveryRequest>();
  const {recoveryProcessReqId} = useParams<{recoveryProcessReqId: string}>();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  // const [regulationLaunched, setRegulationLaunched] = useState<RegLaunched[]>();

  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [closingTransactionModal, setClosingTransactionModal] = useState(false);

  const [isTokenTransferVisible, setIsTokenTransferVisible] = useState<boolean>();
  // const [selectedRegToTransfer, setSelectedRegToTransfer] = useState<RegLaunched>();
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();
  const [transactionModalTitle, setTransactionModalTitle] = useState('');

  const [oldBalance, setOldBalance] = useState<string>();
  const [newBalance, setNewBalance] = useState<string>();
  const [oldWhitelistedWallet, setOldWhitelistedWallet] = useState<WhitelistedWallet>();
  const [newWhitelistedWallet, setNewWhitelistedWallet] = useState<WhitelistedWallet>();
  const [oldWalletWalletWhitelistRequest, setOldWalletWalletWhitelistRequest] = useState<WalletWhitelistRequest>();

  const [partitions, setPartitions] = useState<string[]>();
  const [oldPartitionBalances, setOldPartitionBalances] = useState<string[]>();
  const [oldPartitionInfos, setOldPartitionInfos] = useState<RegLaunched[]>();
  const [newPartitionBalances, setNewPartitionBalances] = useState<string[]>();
  const [newPartitionInfos, setNewPartitionInfos] = useState<RegLaunched[]>();
  const [selectedPartition, setSelectedPartition] = useState<string>();
  const [issuerComplianceAdminWallet, setIssuerComplianceAdmin] = useState('');

  const tokenSymbol = symbolDetailsAndSTData?.securityTokenData.symbol;
  const decimals = +(symbolDetailsAndSTData?.securityTokenData.decimals as string);

  const history = useHistory();

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [txParams, setTxParams] = useState<any>();

  useEffect(() => {
    (async () => {

      let _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);


      let [_recoveryProcessReqRes, _issuerComplianceAdminWallet] = await Promise.all([
        recoveryRequestsService.getRecoveryProcessRequestById({recoveryProcessReqId}),
        mainFacet.issuerComplianceAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      ]);

      const _recoveryProcessReq = _recoveryProcessReqRes.data as RecoveryRequest;

      const [
        _oldBalance,
        _newBalance,
        _oldWhitelistedWallet,
        _newWhitelistedWallet,
        _oldWalletWalletWhitelistRequestRes,
        _partitions,
      ] = await Promise.all([
        erc20Facet.balanceOf(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _recoveryProcessReq.oldWallet
        ),
        erc20Facet.balanceOf(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _recoveryProcessReq.newWallet
        ),
        whitelistFacet.getWhitelistedWallet(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _recoveryProcessReq.oldWallet
        ),
        whitelistFacet.getWhitelistedWallet(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _recoveryProcessReq.newWallet
        ),
        recoveryRequestsService.getWalletWhitelistRequestByAddress(_recoveryProcessReq.oldWallet),
        erc1410Facet.totalPartitions(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
        ),
      ]);

      const [
        _oldPartitionBalances,
        _oldPartitionInfos,
        _newPartitionBalances,
        _newPartitionInfos
      ] = await Promise.all([
        Promise.all(
          _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
            _symbolDetailsAndSTData.securityTokenData.contractAddress,
            _partition,
            _recoveryProcessReq.oldWallet
          ))
        ),
        Promise.all(
          _partitions.map(_partition => mainFacet.getPartitionInfo(
            _symbolDetailsAndSTData.securityTokenData.contractAddress,
            _partition,
          ))
        ),
        Promise.all(
          _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
            _symbolDetailsAndSTData.securityTokenData.contractAddress,
            _partition,
            _recoveryProcessReq.newWallet
          ))
        ),
        Promise.all(
          _partitions.map(_partition => mainFacet.getPartitionInfo(
            _symbolDetailsAndSTData.securityTokenData.contractAddress,
            _partition,
          ))
        ),
      ]);


      setOldBalance(_oldBalance);
      setNewBalance(_newBalance);
      setOldWhitelistedWallet(_oldWhitelistedWallet);
      setNewWhitelistedWallet(_newWhitelistedWallet);
      setOldWalletWalletWhitelistRequest(_oldWalletWalletWhitelistRequestRes.data);
      setRecoveryProcessReq(_recoveryProcessReq);

      setPartitions(_partitions);
      setOldPartitionBalances(_oldPartitionBalances);
      setOldPartitionInfos(_oldPartitionInfos);
      setNewPartitionBalances(_newPartitionBalances);
      setNewPartitionInfos(_newPartitionInfos);
      setIssuerComplianceAdmin(_issuerComplianceAdminWallet);

      // let _regulationsLaunched = __regulationsLaunched.map(launched => ({
      //   regulation: launched.regulation,
      //   dateLaunched: launched.dateLaunched,
      //   regDTransferableOutsideUSA: launched.regDTransferableOutsideUSA,
      //   creationTS: launched.creationTS,
      //   index: launched.index,
      //   legendRemoved: launched.legendRemoved,
      //   typeOfSecurity: launched.typeOfSecurity
      // }));

      // for(let _regulationLaunched of _regulationsLaunched) {
      //
      //   let [oldWalletBalance, newWalletBalance] = await Promise.all([
      //     mainFacet.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.oldWallet, +_regulationLaunched.index),
      //     mainFacet.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, _recoveryProcessReq.newWallet, +_regulationLaunched.index),
      //   ]);
      //   _regulationLaunched['oldWalletBalance'] = oldWalletBalance;
      //   _regulationLaunched['newWalletBalance'] = newWalletBalance;
      // }


      // setRegulationsLaunched(_regulationsLaunched);
  
      setLoading(false);
  
    })();
  },[recoveryProcessReqId]);



  const displayTokenAmount = (amount) => {
    return new BigNumber(amount).times(new BigNumber(10).pow(-(symbolDetailsAndSTData?.securityTokenData.decimals as string))).toFixed();
  }

  const partitionColumns = [
    {
      title: 'Partition',
      dataIndex: 'partition',
      render: (value: string) => sharedService.bytes32ToString(value),
    },

    {
      title: 'Regulation',
      dataIndex: 'regulation',
      render: (value: string) => sharedService.regOptions.find(
        (opt) => opt.shortValue === value
      )?.name,
    },
    {
      title: 'Launched on',
      dataIndex: 'dateLaunched',
      render: (value: string) => moment(+value * 1000).format('LLL'),
    },
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      render: (value: string) => `${sharedService.typeOfSecurityName(value)}`,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount(value)} ${tokenSymbol}`,
    },
      {
        title: 'Action',
        render: (value, row)=> (
          <>
            {row['isNewWallet'] &&
              <Button onClick={()=>openIssueRegModal(row)} type='primary'>ISSUE NEW TOKENS AND WHITELIST</Button>
            }
          </>
        )
      }
  ];


  // const regBalanceColumns = [
  //   {
  //     title: 'Regulation',
  //     dataIndex: 'regulationName'
  //   },
  //   {
  //     title: 'Launched on',
  //     dataIndex: 'dateLaunched',
  //     render: (value: string) => moment(+(value)*1000).format('LLL')
  //   },
  //   {
  //     title: 'Type of Security',
  //     dataIndex: 'typeOfSecurity',
  //     render: (value: string) => `${sharedService.typeOfSecurityName(value)}`
  //   },
  //   {
  //     title: 'Amount',
  //     dataIndex: 'amount',
  //     render: (value: string) => `${displayTokenAmount(value)} ${tokenSymbol}`
  //   },
  //   {
  //     title: 'Action',
  //     render: (value, row: RegLaunched)=> (
  //       <>
  //         {row['isNewWallet'] &&
  //           <Button onClick={()=>openIssueRegModal(row)} type='primary'>ISSUE NEW TOKENS AND WHITELIST</Button>
  //         }
  //       </>
  //     )
  //   }
  // ];

  
  const openIssueRegModal = async (row: {partition: string}) => {
    // setSelectedRegToTransfer(reg);
    setSelectedPartition(row.partition);
    setIsTokenTransferVisible(true);
  }


  const openTxFeeDelegationModal = async(formValue) => {
    setTxParams(formValue);
    setIsDelegationModalVisible(true);
  }

  
  const burn = async(prop: {delegate: boolean}) => {
    setTransactionModalTitle('Removing Wallet');

    setIsModalVisible(true);

    setTransactions([
      { details: 'Removing Wallet from Whitelist', submitting: true },
      { details: 'Burning tokens' }
    ]);

    try {

      const receipt0 = await whitelistFacet.removeWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        recoveryProcessReq?.oldWallet as string,
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if(!receipt0.status) return;

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });


      const receipt1 = await erc1594Facet.redeemFrom(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        recoveryProcessReq?.oldWallet as string,
        oldBalance as string,
        '0x',
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }



  const issue = async(prop: {delegate: boolean}) => {
    console.log(txParams);

    setTransactionModalTitle('Adding Wallet');

    const _from = '0x0000000000000000000000000000000000000000';
    const _to = recoveryProcessReq?.newWallet as string;
    const _value = new BigNumber(txParams.amount).times(new BigNumber(10).pow(decimals)).decimalPlaces(0).toFixed();

    setLoadingCanTransfer(true);

    const canTransfer = await erc1410Facet.canTransferByPartition(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      _from,
      _to,
      selectedPartition as string,
      _value,
    );


    if(canTransfer[0] !== '0x51' && sharedService.bytes32ToString(canTransfer[1]) !== 'Receiver Wallet not whitelisted') {
      notification.error({
        message: 'Token Transfer error',
        description: sharedService.bytes32ToString(canTransfer[1]),
      });
      return;
    }

    // console.log(canTransfer);
    setLoadingCanTransfer(false);

    setIsModalVisible(true);
    setIsTokenTransferVisible(false);


    setTransactions([
      { details: 'Whitelisting Wallet', submitting: true },
      { details: 'Issuing tokens' }
    ]);

    try {

      const receipt0 = await whitelistFacet.addWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        _to,
        oldWalletWalletWhitelistRequest?.['user'].isAffiliate,
        oldWalletWalletWhitelistRequest?.country as string,
        oldWalletWalletWhitelistRequest?.isAccredetedInvestor as boolean,
        {delegate: prop.delegate}
        // oldWalletWalletWhitelistRequest?.assetCreationTS as number
        // !!(recoveryProcessReq?.['user'] as User).isAffiliate,
        // !!(recoveryProcessReq?.['user'] as User)['isFromUSA'],
        // !!(recoveryProcessReq?.['user'] as User)['isAccredetedInvestor'],
      );


      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if(!receipt0.status) return;

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });


      const receipt1 = await erc1410Facet.issueByPartition(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        selectedPartition as string,
        _to,
        _value,
        '0x',
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });
  }



  const closeModal = async() => {
    setClosingTransactionModal(true);

    const [
      _oldBalance,
      _newBalance,
      _oldWhitelistedWallet,
      _newWhitelistedWallet,
    ] = await Promise.all([
      erc20Facet.balanceOf(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        recoveryProcessReq?.oldWallet as string
      ),
      erc20Facet.balanceOf(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        recoveryProcessReq?.newWallet as string
      ),
      whitelistFacet.getWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        recoveryProcessReq?.oldWallet as string
      ),
      whitelistFacet.getWhitelistedWallet(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        recoveryProcessReq?.newWallet as string
      ),

    ]);

    const [
      _oldPartitionBalances,
      _oldPartitionInfos,
      _newPartitionBalances,
      _newPartitionInfos
    ] = await Promise.all([
      Promise.all(
        (partitions as string[]).map(_partition => erc1410Facet.balanceOfByPartition(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          _partition,
          recoveryProcessReq?.oldWallet as string
        ))
      ),
      Promise.all(
        (partitions as string[]).map(_partition => mainFacet.getPartitionInfo(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          _partition
        ))
      ),
      Promise.all(
        (partitions as string[]).map(_partition => erc1410Facet.balanceOfByPartition(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          _partition,
          recoveryProcessReq?.newWallet as string
        ))
      ),
      Promise.all(
        (partitions as string[]).map(_partition => mainFacet.getPartitionInfo(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          _partition
        ))
      ),
    ]);


    setOldBalance(_oldBalance);
    setNewBalance(_newBalance);
    setOldWhitelistedWallet(_oldWhitelistedWallet);
    setNewWhitelistedWallet(_newWhitelistedWallet);

    setOldPartitionBalances(_oldPartitionBalances);
    setOldPartitionInfos(_oldPartitionInfos);
    setNewPartitionBalances(_newPartitionBalances);
    setNewPartitionInfos(_newPartitionInfos);

    setClosingTransactionModal(false);
    setIsModalVisible(false);
  }


  const approveRecoveryProcessRequest = async() => {
    setSubmitting(true);

    const response = await recoveryRequestsService.approveRecoveryProcessRequest({recoveryProcessReqId});

    if (response.success) {
      notification.success({message: 'SUCCESS', description: 'Request approved successfully'});
      history.push('/recovery-requests');
  
    } else {
      console.error(response.error);
      notification.error({message: 'ERROR', description: response.error.message});
    }

    setSubmitting(false);

  }

 


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={2} style={{textAlign:'center'}}>Securities Recovery Process</Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }
              
              {symbolDetailsAndSTData?.symbolDetails.isDeployed && 
                <>
                  {selectedWallet?.toLowerCase() !== issuerComplianceAdminWallet.toLowerCase() &&
                    <WrongMetamaskWalletWarning address={issuerComplianceAdminWallet}/>
                  }

                  {selectedWallet?.toLowerCase() === issuerComplianceAdminWallet.toLowerCase() && 
                    <>
                    <div>

                      <div style={{
                        backgroundColor: 'beige', 
                        width: '70%', 
                        margin: 'auto', 
                        textAlign: 'center', 
                        fontWeight: 'bold', 
                        borderStyle: 'groove'
                      }}>
                         {recoveryProcessReq?.reason}
                      </div>
                      <br/>

                      <div>
                        <Title style={{textAlign:'center'}} level={3}>Old Wallet</Title>

                        <Title style={{textAlign:'center', marginTop: '0px'}} level={3}>
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${recoveryProcessReq?.oldWallet}`}>
                            {/* {sharedService.minifyAddress(recoveryProcessReq?.oldWallet.toLowerCase() as string)} */}
                            {recoveryProcessReq?.oldWallet.toLowerCase() as string}
                          </a> 
                        </Title>
                      </div>

                      {/*<Title style={{textAlign:'center'}} level={3}>Balance</Title>*/}
                      {/*<Title style={{textAlign:'center'}} level={4}>{displayTokenAmount(oldBalance)} {tokenSymbol}</Title>*/}


                      <Descriptions bordered column={1} style={{}}>
                        <Descriptions.Item style={{textAlign:'center'}} label="Balance">
                          {displayTokenAmount(oldBalance)} {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item style={{textAlign:'center'}} label="Whitelisted">
                          {oldWhitelistedWallet?.isWhiteListed? 'YES' : 'NO'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Table
                        columns={partitionColumns}
                        rowKey='partition'
                        pagination={false}
                        dataSource={partitions?.map((partition, index) => ({
                          partition,
                          regulation: oldPartitionInfos?.[index].regulation,
                          dateLaunched: oldPartitionInfos?.[index].dateLaunched,
                          typeOfSecurity: oldPartitionInfos?.[index].typeOfSecurity,
                          amount: oldPartitionBalances?.[index],
                          isNewWallet: false
                        }))}
                      />
                      <div style={{textAlign:'right', marginTop:'20px'}}>
                        <Button style={{marginRight:'5px'}} danger size='large' onClick={() => openTxFeeDelegationModal({txType: 'burn'})}>
                          BURN ALL AND REMOVE FROM WHITELIST
                        </Button>
                      </div>
                    </div>

                    <br/>
                    <hr/>
                    <br/>

                    <div>

                      <div>
                        <Title style={{textAlign:'center'}} level={3}>New Wallet</Title>

                        <Title style={{textAlign:'center', marginTop: '0px'}} level={3}>
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${recoveryProcessReq?.newWallet}`}>
                            {/* {sharedService.minifyAddress(recoveryProcessReq?.newWallet.toLowerCase() as string)} */}
                            {recoveryProcessReq?.newWallet.toLowerCase() as string}
                          </a> 
                        </Title>
                      </div>

                      {/*<Title style={{textAlign:'center'}} level={3}>Balance</Title>*/}

                      <Descriptions bordered column={1} style={{}}>
                        <Descriptions.Item style={{textAlign:'center'}} label="Balance">
                          {displayTokenAmount(newBalance)} {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item style={{textAlign:'center'}} label="Whitelisted">
                          {newWhitelistedWallet?.isWhiteListed? 'YES' : 'NO'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Table
                        columns={partitionColumns}
                        rowKey='partition'
                        pagination={false}
                        dataSource={partitions?.map((partition, index) => ({
                          partition,
                          regulation: newPartitionInfos?.[index].regulation,
                          dateLaunched: newPartitionInfos?.[index].dateLaunched,
                          typeOfSecurity: newPartitionInfos?.[index].typeOfSecurity,
                          amount: newPartitionBalances?.[index],
                          isNewWallet: true
                        }))}
                      />

                      {/*<div style={{textAlign:'right', marginTop:'20px'}}>*/}
                      {/*  <Button style={{marginRight:'5px'}} type="primary" size='large' onClick={openIssueRegModal}>*/}
                      {/*    ADD TO WHITELIST AND ISSUE TOKENS*/}
                      {/*  </Button>*/}
                      {/*</div>*/}
                    </div>
                      
                    <br/><br/>
                    <div style={{textAlign:'center', marginTop:'20px'}}>
                      <Button style={{marginRight:'5px', width: '40%'}} size='large' onClick={() => history.push('/recovery-requests')}>
                        BACK
                      </Button>
                      <Button style={{marginRight:'5px', width: '40%'}} loading={submitting} type="primary" size='large' onClick={approveRecoveryProcessRequest}>
                        FINISH REQUEST
                      </Button>
                    </div>
                    <br/>

                    </>
                  }
                </>
              }


            </Card>
            
          }
        </Col>
      </Row>

      <Modal
        title={'Amount to Issue'}
        okText='ISSUE'
        cancelText='Cancel'
        visible={isTokenTransferVisible}
        onOk={()=>form.submit()}
        okButtonProps={{loading: loadingCanTransfer}}
        onCancel={()=>{setIsTokenTransferVisible(false); form.resetFields()}}
      >
        <Form
          form={form}
          autoComplete={'off'}
          initialValues={{to: recoveryProcessReq?.newWallet}}
          onFinish={(formValue) => openTxFeeDelegationModal({txType: 'issue', ...formValue})}
        >

          <Form.Item wrapperCol={{span:18, offset:3}}
            name='to'
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true
              },
              {
                validator: (rule, value) => {
                  if(value && !sharedService.isEthereumAddress(value)) return Promise.reject("Enter a valid Address");
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input disabled placeholder='Receiver'/>
          </Form.Item>

          <Form.Item wrapperCol={{span:18, offset:3}}
            name='amount'
            normalize = {(value: any, prevValue: any, allValues) => {
              if(new BigNumber(value).isGreaterThan(0)) return value;
              if(!value) return '';
              return prevValue || '';
            }}
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true,
              }
            ]}
          >
            <Input placeholder='Amount'/>
          </Form.Item>

          <br/>

          </Form>

      </Modal>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          txParams.txType === 'burn'? burn({delegate}).then() : issue({delegate}).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />


      <TransactionModal
        title = {transactionModalTitle}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closingModal={closingTransactionModal}
        closeModal = {closeModal}
      />
    </>
  );
}