import React from 'react';
import { AuthService } from '../../Shared/Auth.service';
import { Card, Row, Col, Result } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import NorthCapitalOffering from './CreateNorthCapitalOffering';
import PrimeTrustOffering from './CreatePrimeTrustOffering';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

export default function TokenConfigurationDetails() {
  const { userInfo, setUserInfo }: any = useUserContext();

  console.log(userInfo)

  return (
    <>
      <br />
      {userInfo?.status.basicInfo === 'rejected' &&
        <Row justify="center">
          <Col span={16} style={{ textAlign: 'center' }}>
            <Card>
              <h2 style={{ textAlign: 'center' }}>Basic information verification request has been rejected</h2>
              <Result
                title={`Re-submit your basic information `}
              // subTitle="CCAP team is reviewing your request"
              // extra={
              //   <Button key="console">
              //     Email didn't arrive?
              //   </Button>
              // }
              />
            </Card>
          </Col>
        </Row>
      }

      {userInfo?.status.basicInfo === 'pending' &&
        <Row justify="center">
          <Col span={16} style={{ textAlign: 'center' }}>
            <Card>
              <h2 style={{ textAlign: 'center' }}>Basic information verification request submitted</h2>
              <Result
                title={`TAP team is reviewing your request`}
              // subTitle="CCAP team is reviewing your request"
              // extra={
              //   <Button key="console">
              //     Email didn't arrive?
              //   </Button>
              // }
              />
            </Card>
          </Col>
        </Row>
      }

      {userInfo?.status.basicInfo === 'verified' && userInfo?.northCapitalSelected !== undefined && userInfo?.primeTrustSelected !== undefined ? (
        <>
          {
            userInfo?.northCapitalSelected ?
              <NorthCapitalOffering />
              : <PrimeTrustOffering />
          }
        </>
      ) : (
        <Row justify="center">
          <Col span={16} style={{ textAlign: 'center' }}>
            <Card>
              <h2 style={{ textAlign: 'center' }}>Payment Method Pending</h2>
              <Result
                title={`Please Complete Payment Method First (Do select either North Capital or Prime Trust)`}
              // subTitle="CCAP team is reviewing your request"
              // extra={
              //   <Button key="console">
              //     Email didn't arrive?
              //   </Button>
              // }
              />
            </Card>
          </Col>
        </Row>
      )
      }
    </>
  );
}
