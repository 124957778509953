import React, { useEffect, useState } from "react";
import { Checkbox, Space } from "antd";
import { InvestorService } from "../Investor.service";
import { AuthService } from "../../Shared/Auth.service";
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
export default ({ key, style }) => {
  const { userInfo } = useUserContext();
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [isFromUSA] = useState(
    userInfo?.country?.toLowerCase() === "united states of america"
  );
  useEffect(() => {
    (async () => {
      const response = await investorService.getPayments({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setPaymentDone(false);
        } else {
          setPaymentDone(true);
          let paymentAccepted = response.data[0].accept;
          setPaymentApproved(paymentAccepted);
        }
      }
    })();
  }, [key]);

  return (
    <>
      <Space style={{ ...style, textAlign: "left" }} direction="vertical">
        <Checkbox checked={userInfo?.status?.emailVerified}>
          Self verification
        </Checkbox>
        <Checkbox checked={userInfo?.tAndCSigned}>
          Acceptance of Terms and Conditions
        </Checkbox>
        {isFromUSA && (
          <Checkbox checked={userInfo?.aiSubmittedCompany?.length > 0}>
            Accredited investor verification
          </Checkbox>
        )}
        <Checkbox checked={userInfo?.KYCSubmittedCompany?.length > 0}>
          KYC submission
        </Checkbox>
        <Checkbox checked={paymentDone}>Payment</Checkbox>
        <Checkbox checked={userInfo?.KYCVerifiedCompany?.length > 0}>
          Approval of KYC
        </Checkbox>
        {isFromUSA && (
          <Checkbox checked={userInfo?.aiVerifiedCompany?.length > 0}>
            Approval of Accredited investor verification
          </Checkbox>
        )}
        <Checkbox checked={paymentApproved}>Approval of Payment</Checkbox>
      </Space>
    </>
  );
};
