import React, { useState, useEffect } from "react";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerTokenAdminService } from "../IssuerTokenAdmin.service";
import {
  Row,
  Col,
  Typography,
  Card,
  Descriptions,
  Button,
  message,
  Table,
  Badge,
  Tabs,
  Form,
  Input,
  DatePicker,
} from "antd";
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { OmitProps } from "antd/lib/transfer/ListBody";
import moment from 'moment';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const issuerTokenAdminService = new IssuerTokenAdminService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [detailsView, setDetailsView] = useState(false);
  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "MODE",
      dataIndex: "modeOfFundTransfer",
      key: "mode",
    },
    {
      title: "AMOUNT",
      dataIndex: "amountToInvest",
      key: "amountToInvest",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, investor) => (
        <Button type="primary" onClick={() => showDetails(investor)}>
          DETAILS
        </Button>
      ),
    },
  ];
  const columns1 = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "MODE",
      dataIndex: "modeOfFundTransfer",
      key: "mode",
    },
    {
      title: "AMOUNT",
      dataIndex: "amountToInvest",
      key: "amountToInvest",
    },
    {
      title: "Tokens To Distribute",
      dataIndex: "no_of_tokens",
      key: "no_of_tokens",
      render: (no_of_tokens) => {
        return no_of_tokens ? <span>{no_of_tokens}</span> : <span>0</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "action",
      key: "action",
      render: (text, investor) => {
        return (
          <>
            {investor.accept && (
              <span style={{ fontWeight: "bold" }}>Approved</span>
            )}
            {investor.reject && (
              <span style={{ fontWeight: "bold" }}>Rejected</span>
            )}
          </>
        );
      },
    },
  ];
  const [investorDetails, setCurrentInvestor] = useState();
  const [investors, setInvestors] = useState(null);
  const [approvedAndRejectedInvestors, setInvestorsList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [paymentReciept, setPaymentReciept] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentInvestmentStatus, setCurrentInvestmentStatus] = useState(null);
  const [recieptForm] = Form.useForm();
  const [tokensToAlot, setTokensToAlot] = useState(null);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await issuerTokenAdminService.getPaymentDetails({
        company: userInfo.companyName.toLowerCase(),
      });
      if (response.success && response.data) {
        console.log(response.data);
        setInvestors(response.data);
      }
    })();
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await issuerTokenAdminService.getCompletedPaymentDetails(
        { company: userInfo.companyName }
      );
      if (response.success && response.data) {
        setInvestorsList(response.data);
      }
    })();
  }, [userInfo]);

  const investorDetail = async () => {
    setSubmitting(true);
    const response = await issuerTokenAdminService.getPaymentDetails({
      company: userInfo.companyName,
    });
    if (response.success && response.data) {
      console.log(response.data);
      setInvestors(response.data);
    }
    setSubmitting(false);
  };
  const showDetails = async (investor) => {
    // COMMENTED BECAUSE FUNDAMERICA IS NOT ENABLED

    // const response = await issuerTokenAdminService.getPaymentStatus({
    //   investor: investor,
    // });
    // if (response.success && response.data) {
    //   if (response.data.status == "received") {
    //     setCurrentStatus(true);
    //   } else setCurrentStatus(false);
    //   setCurrentInvestmentStatus(response.data.status);
    // }
    setCurrentStatus(true);
    setCurrentInvestor(investor);
    setDetailsView(true);
  };
  const acceptPayment = async (data, status) => {
    setSubmitting(true);
    /* if(status!='received')  
    {
      message.error("You can't approve the payment which is not received");
      setSubmitting(false);
      return;
    }*/
    data.accept = true;
    const response = await issuerTokenAdminService.acceptPayment(data);
    if (response.success || response.data) {
      setDetailsView(false);
      setPaymentReciept(false);

      const response = await issuerTokenAdminService.getPaymentDetails({
        company: userInfo.companyName.toLowerCase(),
      });
      if (response.success && response.data) {
        console.log(response.data);
        setInvestors(response.data);
      }

    } else {
      message.error("Some error occured!");
    }
    setDetailsView(false);
    setSubmitting(false);
  };
  const rejectPayment = async (data) => {
    setSubmitting(true);
    data.reject = true;
    const response = await issuerTokenAdminService.rejectPayment(data);
    if (response.success && response.data) {
      setDetailsView(false);
      setPaymentReciept(false);
    } else {
      message.error("Some error occured!");
    }
    setSubmitting(false);
  };

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Investor Payment Details
            </Title>
            <Tabs defaultActiveKey="request" centered>
              <TabPane
                tab={
                  <span>
                    <PlaySquareOutlined />
                    Pending Payment Approval{" "}
                  </span>
                }
              >
                <div hidden={detailsView || paymentReciept}>
                  <p style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => investorDetail()}
                      loading={submitting}
                    >
                      Refresh
                    </Button>
                  </p>
                  <Table dataSource={investors} columns={columns} />
                </div>
                <div hidden={!detailsView || paymentReciept}>
                  <Descriptions
                    title="Investor Details"
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    bordered
                  >
                    <Descriptions.Item label="Name">
                      {investorDetails?.firstName +
                        " " +
                        investorDetails?.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {investorDetails?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Amount invested">
                      {investorDetails?.amountToInvest}
                    </Descriptions.Item>
                    <Descriptions.Item label="Number of tokens">
                      {investorDetails?.no_of_tokens}
                    </Descriptions.Item>
                    <Descriptions.Item label="Service Provider">
                      {investorDetails?.serviceProvider}
                    </Descriptions.Item>
                    <Descriptions.Item label="Reference Number">
                      {investorDetails?.refrenceNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of Transfer">
                      {
                        investorDetails?.dateOfAmountReceived || 
                        moment(investorDetails?.creationTS).format('LLL')
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="Investment Status">
                      Received
                    </Descriptions.Item>
                    {!currentStatus && (
                      <Descriptions.Item label="Status">
                        <Badge
                          color="yellow"
                          status="processing"
                          text={`Waiting For Payment Approval`}
                        ></Badge>
                      </Descriptions.Item>
                    )}
                    {currentStatus && (
                      <Descriptions.Item label="Status">
                        <Badge
                          color="yellow"
                          status="success"
                          text={`Payment Approved`}
                        ></Badge>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                  <br />
                  <Button
                    type="primary"
                    onClick={() =>
                      acceptPayment(investorDetails, currentInvestmentStatus)
                    }
                    loading={submitting}
                  >
                    Approve
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => rejectPayment(investorDetails)}
                    disabled={submitting}
                  >
                    Reject
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => setDetailsView(false)}
                    disabled={submitting}
                  >
                    Back
                  </Button>
                </div>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ProjectOutlined />
                    Approved/Rejected Payments
                  </span>
                }
                key="2"
              >
                <Table
                  columns={columns1}
                  dataSource={approvedAndRejectedInvestors}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};
