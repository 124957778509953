import React, {useState, useEffect} from 'react'
import {message, Form, Spin, Input, Checkbox, Button} from "antd"
import { InvestorService } from '../Investor.service'
import ppm from "../../../assets/Documents/CAR - PPM.pdf"
import eversign from "./Eversign"

const investorService = new InvestorService();
const { Item } = Form

const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 6,
    },
  }

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 8,
    },
  }

export default ({ token, userInfo }) => {

    const [loading, setLoading] = useState(false)
    const [skrillSessionId, setSkrillSessionId] = useState("")
    const [ppmAccepted, setPPMAccepted] = useState(false)
    const [amount, setAmount] = useState()
    const [signURL, setSignURL] = useState(null)

    useEffect(() => {
        if (skrillSessionId && skrillSessionId !== null) {
            const skrillCheckoutUrl = `https://pay.skrill.com/?sid=${skrillSessionId}`
            window.location.href = skrillCheckoutUrl
        }
    }, [skrillSessionId])
    
    const openSkrillCheckout = async () => {
        setLoading(true)
        try {
            const skrillData = { amountToInvest: amount, userInfo }
            const res =  await investorService.payUsingSkrill(skrillData)
            const { data } = res
            setSkrillSessionId(data)
        } catch (e) {   
            console.log(e)
            message.error("Sommething Went Wrong")
        }
        setLoading(false)
    }

    const fetchSignUrl = async ({ amountToInvest }) => {
        setAmount(amountToInvest)
        try {
          const noOfTokens = Number((amountToInvest / token.pricePerToken) * 100)
          const data = {...userInfo, noOfTokens}
          const response = await investorService.getInvestSignURL(data)
          setSignURL(response.data)
        } catch (e) {
          console.log(e)
          message.error("Something Went Wrong")
        }
      }

    const validation = [
        {
          required: true,
          message: "Please enter a valid amount",
        },
        {
          validator: (text, value) => {
            let isNum = /^\d+$/.test(value);
            if (!isNum)
              return new Promise(async (resolve, reject) =>
                reject("Please enter a valid amount")
              );
            else return new Promise(async (resolve, reject) => resolve());
          },
        },
      ];

    return (
        <>
            <div style={{ textAlign: "left" }}>
                <div hidden={signURL !== null}>
                  {loading ? <Spin /> :  
                    <Form {...layout} onFinish={fetchSignUrl} >
                    <Item
                        name="amountToInvest"
                        label="Amount To Invest"
                        rules={validation}
                    >
                        <Input />
                    </Item>
                    <Item>
          	            <Checkbox
          		            checked={ppmAccepted}
          		            onChange={e => setPPMAccepted(e.target.checked)}
          	            >
          	                <a target="#" href={ppm}>
                                I understand and agree to the PPM of the offering
                            </a>
          	            </Checkbox>
                    </Item>
                    <Item {...tailLayout}>
                        <Button 
                            disabled={!ppmAccepted} 
                            htmlType="submit" 
                            type="primary"
                        >
                            Submit
                        </Button>
                        {"  "}
                    </Item>
                </Form>
                }
                </div>
                <div id="embedInvestSkrillDivision">
                    <>
                    {signURL !== null &&
                        eversign.open({
                        url: signURL,
                        containerID: "embedInvestSkrillDivision",
                        width: 750,
                        height: 800,
                        events: {
                        loaded: () => console.log("load hogya"),
                        signed: openSkrillCheckout,
                        error: () => console.log("EVERSIGN ERROR"),
                        declined: () => console.log("declined"),
                    },
                    })
                    }
                    </>
                </div>
            </div>
        </>
    )
}