export const environment = {
  production: false,
  apiKey:'5f3cd49bf3bc85f2558e6421',
  captchaKey:'6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI',
  APIURL: 'https://dev-tap-backend-master.herokuapp.com',
  headerTitle: 'ISSUER-DEV',
  securityTokenRegistryAddress: '0xcD72D27645DD7356AD7FfA571c013107CA53B4F6',
  allowedNetworks: ['11155111'],
  NORTH_CAPITAL_API_KEY: '6hLi5AHquGXBmqRh6n6s7JQg07VQKXYngm7',
  NORTH_CAPITAL_CLIENT_ID: '4MdYUpAArsDBZ7e',
  monthUnitInSeconds: 60,
}
