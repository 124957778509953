import React, { useEffect,useState } from 'react'
import {Table,Card,Col,Row,Typography, Descriptions,Button, Select,Form,Badge, Radio, Input,Progress, message,Tabs,List,Popconfirm} from 'antd'
import {PlatformComplianceAdminService} from '../PlatformComplianceAdmin.service'
import { SharedService } from '../../Shared/Shared.service';
import ExportToExcel from './ExportToExcel'
import moment from 'moment'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
const {Title}=Typography
const platformComplianceAdminService=new PlatformComplianceAdminService()
const sharedService=new SharedService();
const {TabPane}=Tabs



export default ()=>{
  const [investors,setInvestors]=useState()
  const [detailsDivision,setDetailsDivision]=useState(true)
  const [detailsDivision1,setDetailsDivision1]=useState(true)
  const [investorDetails,setInvestorDetails]=useState()
  const [submitting,setSubmitting]=useState(false)
  const [filteredInvestor,setFilteredInvestor]=useState(investors)
  const [issuers,setIssuers]=useState(null)
  const [KYCResultSubmitted,setKYCResultSubmitted]=useState(false);
  const [form]=Form.useForm();
  const [yes,setYes]=useState(false)
  const [documents,setDocuments]=useState([])
  const [documentsToSubmit,setDocumentsToSubmit]=useState([])
  const [no,setNo]=useState(false)
  const [approvedAndRejectedKYCRequests,setInvestorsList]=useState([])
  const [KYCResultFile,setKYCResult] = useState(
    {
      KYCResultFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );
  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'ROLE',
      dataIndex:'role',
      key:'role'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetails(investor)}>DETAILS</Button>
    },
    {
      title:'Export to Excel',
      dataIndex:'action2',
      key:'action1',
      render:(text,investor)=><ExportToExcel data={investor} fileName={investor.firstName+"_"+investor.lastName} ></ExportToExcel>
    }

  ]
  const columns2=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetailsForResult(investor)}>DETAILS</Button>
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return(
          <>
          {investor.approvedByICA && <span style={{color:'green'}} >Approved</span>}
          {investor.rejected && <span style={{color:'red'}} >rejected</span>}
          {(!investor.approvedByICA && investor.approved) && <span style={{color:'orange'}} >Approval pending from ICA</span>}
          </>

        )
      }
    }

  ]
  const showDetails=investor=>{
    setDetailsDivision(false)
    setInvestorDetails(investor)
  }
  const showDetailsForResult=investor=>{
    setDetailsDivision1(false)
    setInvestorDetails(investor)
  }
  const rejectKYC=(details)=>{
    (async ()=>{
      setSubmitting(true)
      details.approved=false
      details.rejected=true
      const response=await platformComplianceAdminService.updateTeamMemberKYC(details);
      if(response.success)
      {
        const inv=investors.filter(investor=>investorDetails._id!==investor._id);
        setInvestors(inv);
        setFilteredInvestor(inv);
        setKYCResult({
          KYCResultFile: {
            uploading: false,
            uploadFilePercentage: 0,
            URL: null,
            name: null,
            type: null
          }
        }
        );
        setDocuments([]);
        setDocumentsToSubmit([]);
        setKYCResultSubmitted(false);
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  const acceptKYC=(details)=>{
    (async ()=>{
      setSubmitting(true)
      details.approved=true
      details.rejected=false
      const response=await platformComplianceAdminService.updateTeamMemberKYC(details);
      if(response.success)
      {
        const inv=investors.filter(investor=>investorDetails._id!==investor._id);
        console.log(inv);
        setInvestors(inv);
        setKYCResultSubmitted(false);
        setFilteredInvestor(inv);
        setKYCResult({
          KYCResultFile: {
            uploading: false,
            uploadFilePercentage: 0,
            URL: null,
            name: null,
            type: null
          },
        });
        setDocuments([]);
        setDocumentsToSubmit([]);
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  useEffect(
    ()=>{
      (async ()=>{
        let issuers=null;
        const response=await platformComplianceAdminService.getTeamMemberKYCRequests();
        await setInvestors(response.data)
        issuers=response.data.map(investor=>investor?.company);
        setIssuers(new Array(new Set(issuers)));
      }      
    )();
    },[detailsDivision]
  )

  useEffect(
    ()=>{
      (async ()=>{
        const response=await platformComplianceAdminService.getTeamMemberList();
        await setInvestorsList(response.data)
      }      
    )();
    },[detailsDivision]
  )

  const deleteItem=(index)=>{
    setDocuments(documents.filter(document=>document!==documents[index]));
    setDocumentsToSubmit(documentsToSubmit.filter(document=>document!==documentsToSubmit[index]));
  }

    const filterInvestor=(selected)=>{
      console.log(selected)
      if(selected==="ALL")
      {
        setFilteredInvestor(investors)
        return
      }
      let temp=investors.filter(investor=>selected.has(investor.company.toLowerCase()))
      setFilteredInvestor(temp)
    }


    const uploadFile = (e, key,setFilesObj,form) => {
      if(!e.target.files) return;
    
      const file =  e.target.files[0];
      console.log(file);
    
      if(!file) return;
    
      const fileType = sharedService.getFileType(file.name);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].URL = null;
        return current;
      });
    
      platformComplianceAdminService.uploadFile(file, (uploadFilePercent) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });
    
    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });
      
    }, async(response) => {
    
      if (response.success) {
        const URL = response.data.url;
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        let fileDetails={[key]: {URL,name:file.name}};
        form.setFieldsValue(fileDetails);
        setDocuments(prev=>prev.concat(file.name));
        setDocumentsToSubmit(prev=>prev.concat(fileDetails));
        
      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }
    
    });
    }

  
    
 const saveKYCResult= async (data)=>{
   data.KYCResultFile=documentsToSubmit
   let temp=Object.assign(data,{_id:investorDetails?._id})
   console.log("saveKYC",temp)
   const response=await platformComplianceAdminService.saveTeamMemberKYCResult(temp);
   if(response.success & response.data)
   {
     setKYCResultSubmitted(true);
     setFilteredInvestor(filteredInvestor.filter(investor=>investor.email!==data.email))
   }
 }
const enableDisableButton=(value)=>{
  if(value.target.value=='YES')
  {
    setNo(false);
    setYes(true);
  }
  else{
    setYes(false);
    setNo(true);
  } 
}
  return(
  <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Team Member KYC Request
          </Title>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={<span><PlaySquareOutlined/>Pending KYC Requests</span>}  key="1">
          <div hidden={!detailsDivision}>
            <Row justify='center'>
            <Col>
            <h2 style={{color: '#1890ff'}}>Issuer:&nbsp;&nbsp;</h2>
            </Col>
          <Col>
          <Select style={{width:200}} onChange={filterInvestor}>
            <Select.Option value="ALL">ALL</Select.Option>
          {issuers?.map(issuer=><Select.Option value={issuer} >{issuer}</Select.Option>)}
          </Select>
          </Col>
          </Row>
          <br/>
          <Table id="inv" dataSource={filteredInvestor} columns={columns} />
          </div>
          <div hidden={detailsDivision}>
            <Descriptions title='Team Member Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            bordered>
          <Descriptions.Item label="Selfie"><img height={200} width={200} src={investorDetails?.selfie} ></img></Descriptions.Item>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of KYC Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
          <Descriptions.Item label="Present Address">{<div>{investorDetails?.pAStreetAddress1} <br/>{investorDetails?.pAStreetAddress2} <br/> {investorDetails?.pACity} {investorDetails?.pAZipCode} </div>}</Descriptions.Item>
              <Descriptions.Item label="Present State">{investorDetails?.pAState}</Descriptions.Item>
              <Descriptions.Item label="Present Country">{investorDetails?.pACountry}</Descriptions.Item>
              <Descriptions.Item label="Permanent Address">{<div>{investorDetails?.pStreetAddress1} <br/> {investorDetails?.pStreetAddress2} <br/> {investorDetails?.pCity} {investorDetails?.pZipCode}</div>}</Descriptions.Item>
              <Descriptions.Item label="Permanent State">{investorDetails?.pState}</Descriptions.Item>
              <Descriptions.Item label="Permanent Country">{investorDetails?.pCountry}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide.URL} target="_blank" >{investorDetails?.licenceFrontSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Licence Back Side"><a href={investorDetails?.licenceBackSide.URL} target="_blank">{investorDetails?.licenceBackSide?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Passport"><a href={investorDetails?.passport.URL} target="_blank">{investorDetails?.passport?.name}</a></Descriptions.Item>
              <Descriptions.Item label="Status"><Badge color='yellow' status="processing" text={`Waiting For KYC & AMl/CTF results`}></Badge></Descriptions.Item>
            </Descriptions>
            <br/>
            <div hidden={KYCResultSubmitted}>
              <Form
              form={form}
              onFinish={saveKYCResult}
              >
                <Form.Item
                name='KYCResultFile'
                label="Upload KYC Result File"
                rules={[{
                  required:true,
                  message:"Please select a zip file to proceed!!!"
                }]}
                >
              <Input type='file' onChange={e => { uploadFile(e, 'KYCResultFile',setKYCResult,form) }} disabled={KYCResultSubmitted} />
              {KYCResultFile.KYCResultFile.uploadFilePercentage>0 && 
              <><Progress percent={KYCResultFile.KYCResultFile.uploadFilePercentage} />
              <div align="left">
              Files Uploaded:
              <List  itemLayout='horizontal' style={{textAlign:'left'}}>
    {documents?.map((obj,index)=><List.Item textalign='left' style={{cursor:'pointer'}} key={index} actions={[
      <Popconfirm title='This item will be deleted!' onConfirm={()=>deleteItem(index)}>
      <Button type='primary' danger>X</Button>
      </Popconfirm> ]}>
      <List.Item.Meta title={obj}/></List.Item>)}
    </List></div></>}
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>{`SAVE THE KYC & AML/CTF RESULTS`}</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button htmlType="button" onClick={()=>setDetailsDivision(true)}>Cancel</Button>
            </Form.Item>
              </Form>
            </div>
            <div hidden={!KYCResultSubmitted}>
             Do you want Issuer Compliace Amdin to Approve/Reject the Invstor? &nbsp;&nbsp;&nbsp;&nbsp;
             <Radio.Group onChange={enableDisableButton}>
             <Radio value='YES'>YES</Radio>
             <Radio value='NO'>NO</Radio>
             </Radio.Group>
            <Button hidden={!yes} onClick={()=>acceptKYC(investorDetails)} type='primary' loading={submitting} >Submit</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Form
            onFinish={data=>rejectKYC(Object.assign(investorDetails,data))}
            hidden={!no}
            >
              <Form.Item
              name='rejectDiscription'
              label='Reason'
              rules={[{
                required:true,
                message:"Reason required!!"
              }]}
              >
                <Input.TextArea rows={4} cols={25}></Input.TextArea>
              </Form.Item>
              <Form.Item>
              <Button type="primary" htmlType='submit'  disabled={submitting}>Reject</Button>
              </Form.Item>
            </Form>
            
          </div>
          </div>
          </TabPane>
          <TabPane tab={<span><ProjectOutlined/>Approved/Rejected KYC Requests</span>} key="2">
            <div hidden={!detailsDivision1}>
              <Table columns={columns2} dataSource={approvedAndRejectedKYCRequests}></Table>
              </div>
              <div hidden={detailsDivision1}>
              <Descriptions title='Team Member Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            bordered>
          <Descriptions.Item label="Selfie"><img height={200} width={200} src={investorDetails?.selfie} ></img></Descriptions.Item>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of KYC Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
          <Descriptions.Item label="Present Address">{<div>{investorDetails?.pAStreetAddress1} <br/>{investorDetails?.pAStreetAddress2} <br/> {investorDetails?.pACity} {investorDetails?.pAZipCode} </div>}</Descriptions.Item>
              <Descriptions.Item label="Present State">{investorDetails?.pAState}</Descriptions.Item>
              <Descriptions.Item label="Present Country">{investorDetails?.pACountry}</Descriptions.Item>
              <Descriptions.Item label="Permanent Address">{<div>{investorDetails?.pStreetAddress1} <br/> {investorDetails?.pStreetAddress2} <br/> {investorDetails?.pCity} {investorDetails?.pZipCode}</div>}</Descriptions.Item>
              <Descriptions.Item label="Permanent State">{investorDetails?.pState}</Descriptions.Item>
              <Descriptions.Item label="Permanent Country">{investorDetails?.pCountry}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
            <Descriptions.Item label="Licence Front Side"><a href={investorDetails?.licenceFrontSide} target="_blank">License Front Side</a></Descriptions.Item>
              <Descriptions.Item label="Licence Back Side"><a href={investorDetails?.licenceBackSide} target="_blank">License Back Side</a></Descriptions.Item>
              <Descriptions.Item label="Passport"><a href={investorDetails?.passport} target="_blank">Passport</a></Descriptions.Item>
              <Descriptions.Item label="Status"><Badge color='yellow' status="processing" text={`Waiting For KYC & AMl/CTF results`}></Badge></Descriptions.Item>
            </Descriptions>
            <br></br>
            <Button type="primary" onClick={()=>setDetailsDivision1(true)} >Back</Button>
              </div>
          </TabPane>
          </Tabs>
          </Card>
      </Col>
     </Row>
  </>
  )
}