import React,{useEffect,useState} from 'react';
import {Input,Form,Select, Alert} from 'antd';
import { Card, Row, Col, Typography, Button,message } from 'antd';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service' 
const {TextArea}=Input;
const {Option}=Select;
const {Title} = Typography;
const platformIssuerAdminService=new PlatformIssuerAdminService();

export default () => {
  const [categories,setCategories]=useState< string[] | null>();
  const [submiting,setSubmiting]=useState(false);
  const [expired,setExpired]=useState(false);
  const [addedView,setAddedView]=useState(false)
  const [errorView,setErrorView]=useState(false)
  const [issuers,setIssuers]=useState([])
  const [form]=Form.useForm()
  useEffect(() => {
    (async () => {
      const response= await platformIssuerAdminService.getQuestionCategories();
      const cat=response.data?.['0']?.categories
      setCategories(cat);
    })();
  },[]);
  useEffect(() => {
    (async () => {
      const response= await platformIssuerAdminService.getRegisteredIssuers();
      if(response.success && response.data)await setIssuers(response.data);
      else await message.error(response.error.message);
    })();
  },[]);
  const onFinish= values =>{
    setSubmiting(true);
    setExpired(true);
    (async ()=>{
      console.log(values);
      const response=await platformIssuerAdminService.setQuestion(values);
      console.log(response)
      if(response.success && response.data)
      {
        setSubmiting(false);
        form.resetFields()
        setExpired(false);
        setAddedView(true) 
        message.success("Question Added");
      }
      else{
        setErrorView(true)
        setSubmiting(false);
        setExpired(false);
      }

    })();
  }
  const clearMessages=()=>{
    setAddedView(false)
    setErrorView(false)
  }
  const reset=()=>{
    clearMessages()
    form.resetFields()
  }
  return (
    <>
    
    
     <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            {errorView && message.error("Question Already Added!")}
            Create Information Questions for issuer
          </Title>
           
            <Form
            form={form}
            name='questionForm'
            onFinish={onFinish}
            >
               <Form.Item
              label='Select Issuer'
              initialValue={-1}
              name='companyName'
              rules={[{
                required:true,
                message:'Please select issuer'
              }]}>
              <Select loading={!issuers} onChange={()=>clearMessages()}  id='selectIssuerBox' style={{width:280,marginLeft:100}} disabled={expired}>
              <Option value={-1}>&lt;select&gt;</Option>
              {issuers?.map((issuer,index)=><Option value={issuer} key={index}>{issuer}</Option>)}
              </Select>
              </Form.Item>
              <Form.Item
              label='Select Category of Questions'
              initialValue={-1}
              name='category'
              rules={[{
                required:true,
                message:'Please select category'
              },
              /*{
                validator:(rule,value)=>{
                  if(value===-1)
                  {
                    return Promise.reject("Please select category")
                  }
                }
              }*/]}>
              <Select loading={!categories} onChange={()=>clearMessages()}  id='selectCategoryBox' style={{width:280}} disabled={expired}>
              <Option value={-1}>&lt;select&gt;</Option>
              {categories?.map((category,index)=><Option value={category} key={index}>{category}</Option>)}
              </Select>
              </Form.Item>
              <Form.Item
              label='Enter the Information Question for issuer'
              name='question'
              rules={[{
                required:true,
                message:'Please enter the question'
              }]}> 
              <TextArea rows={5} onChange={()=>clearMessages()} style={{width:280,marginRight:70}} disabled={expired}></TextArea>
              </Form.Item>
              <Form.Item
              label='Type of answer to the question'
              name='type'
              initialValue={-1}
              rules={[{
                required:true,
                message:'Please select the type of answer'
              },
              /*{
                validator:(rule,value)=>{
                  if(value===-1)
                  {
                    return Promise.reject("Please select the type of answer");
                  }
                }
              }*/]}>
              <Select id='answerType' onChange={()=>clearMessages()} style={{width:280}} disabled={expired}>
              <Option value={-1}>&lt;select&gt;</Option>
              <Option value='option'>Yes/No</Option>
              <Option value='textArea'>Rich Text Area</Option>
              <Option value='textBox'>TextBox</Option>
              <Option value='attachement'>Upload attachement</Option>
              </Select>
              </Form.Item>
              <Form.Item>
              <Button type='primary' htmlType='submit' loading={submiting}>SAVE THE QUESTION</Button>&nbsp;&nbsp;&nbsp;<Button disabled={expired} onClick={()=>reset()}>CANCEL</Button>
              </Form.Item>

            </Form>
        </Card>
      </Col>
     </Row>
    </>
  )
}
